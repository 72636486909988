import { useEffect, useState } from "react";
import { useNavbar } from "../../../../context/NavbarContext";
import useGetRequest from "../../../../hooks/getRequest.query";
import useSearchParams from "../../../../hooks/useSearchParams";
import {
    getPatientsByFocusedGroup,
    getAllCHWByFacility,
} from "../../../../services/focused-group/FocusedGroup.hook";
import BeneficiaryTable from "./table/BeneficiaryTable";
import { convertToUtcTimestamp } from "../../../../common/utils";

const BeneficiaryListPage = ({ totalVisits, focusedGroupName }: any) => {
    const facilityCode = localStorage.getItem("facilityCode");
    const { fgId: id }: any = useSearchParams();
    const { selectedFacility } = useNavbar();
    const [pageNumber, setPageNumber] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);

    const [filterData, setFilterData]: any = useState({
        chwId: "",
        visitNumber: "",
        fromDate: "",
        toDate: "",
    });

    const { data, isLoading, isFetching, refetch, isError, error }: any =
        useGetRequest(
            ["getPatientsByFocusedGroup", pageNumber, id, pageLimit],
            async () =>
                await getPatientsByFocusedGroup({
                    page: pageNumber,
                    limit: pageLimit,
                    focusedGroupId: id,
                    facilityCode,
                    visitNumber: filterData?.visitNumber,
                    chwId: filterData?.chwId,
                    // fromDate: filterData?.fromDate,
                    // toDate: filterData?.toDate,
                    fromDate:
                        filterData?.fromDate !== ""
                            ? convertToUtcTimestamp(filterData?.fromDate)
                            : "",
                    toDate:
                        filterData?.toDate !== ""
                            ? convertToUtcTimestamp(filterData?.toDate)
                            : "",
                }),
            {
                retry: false,
                staleTime: 100000,
                refetchOnWindowFocus: false,
                enabled: !!id,
            }
        );

    const totalEntites = data?.data?.results;
    const beneficiaryListData = data?.data?.data?.form;
    const totalPages = Math.ceil(totalEntites / pageLimit);
    const ErrorMsg = error?.response?.data?.message;

    const { data: chwAPIData, refetch: refetchCHW }: any = useGetRequest(
        "allCHWData",
        async () =>
            await getAllCHWByFacility({
                facilityCode,
                page: 1,
                limit: 20,
            }),
        {
            retry: false,
            staleTime: 100000,
            refetchOnWindowFocus: false,
        }
    );

    const chwData = chwAPIData?.data?.data?.chw;

    useEffect(() => {
        refetch();
        refetchCHW();
    }, [filterData, selectedFacility]);

    return (
        <>
            <div className="mt-5">
                <BeneficiaryTable
                    filterData={filterData}
                    pageLimit={pageLimit}
                    currentPageNumber={pageNumber}
                    handlePageNumber={setPageNumber}
                    totalEntites={totalEntites}
                    totalPages={totalPages}
                    isLoading={isLoading}
                    isFetching={isFetching}
                    isError={isError}
                    FgErrorMsg={ErrorMsg}
                    refetch={refetch}
                    setFilterData={setFilterData}
                    totalVisits={totalVisits}
                    beneficiaryListData={beneficiaryListData}
                    focusedGroupName={focusedGroupName}
                    chwData={chwData}
                    setPageLimit={setPageLimit}
                />
            </div>
        </>
    );
};

export default BeneficiaryListPage;
