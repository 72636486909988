import { useEffect, useState } from "react";
import InfoCard from "../../../components/InfoCard";
import "../../../components/styles/Card.css";
import InsightsSkeleton from "../../../components/skeleton/InsightsSkeleton";
import { useNavbar } from "../../../context/NavbarContext";
import useGetRequest from "../../../hooks/getRequest.query";
import { getTaskInsightByFacility } from "../../../services/TaskManagement.hook";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import Filter from "./Filter";
import { convertToUtcTimestamp } from "../../../common/utils";

const TaskInsights = () => {
    const { selectedFacility } = useNavbar();
    // const [period, setPeriod] = useState("30 Days");
    const facilityCode = localStorage.getItem("facilityCode");
    const [filterData, setFilterData]: any = useState({
        fromDate: "",
        toDate: "",
    });

    // const handlePeriod = (event: any) => {
    //     setPeriod(event.target.value);
    // };

    const {
        data,
        isLoading,
        isFetching,
        isError: isTaskError,
        error: taskError,
        refetch,
    }: any = useGetRequest(
        "getTask",
        () =>
            getTaskInsightByFacility({
                facilityCode,
                // ...filterData,
                fromDate:
                    filterData?.fromDate !== ""
                        ? convertToUtcTimestamp(filterData?.fromDate)
                        : "",
                toDate:
                    filterData?.toDate !== ""
                        ? convertToUtcTimestamp(filterData?.toDate)
                        : "",
                // period,
            }),
        { staleTime: 100000, refetchOnWindowFocus: false }
    );

    const taskInsight = data?.data?.data?.taskInsights;
    const errorMsg = taskError?.response?.data?.message;

    const cardList = [
        {
            title: "TOTAL VISITS",
            totalCount: taskInsight?.totalVisits,
            iconType: "/media/ikure/insights/visits.svg",
        },
        {
            title: "TOTAL TASK ADDED",
            totalCount: taskInsight?.totalTaskAdded,
            iconType: "/media/ikure/insights/total-tasks.svg",
        },
        {
            title: "PENDING TASK",
            totalCount: taskInsight?.totalPendingTask,
            iconType: "/media/ikure/insights/pending-tasks.svg",
        },
        {
            title: "TASK IN PROGRESS",
            totalCount: taskInsight?.totalTaskInProgess,
            iconType: "/media/ikure/insights/inprogress-tasks.svg",
        },
        {
            title: "TASK COMPLETED",
            totalCount: taskInsight?.totalTaskCompleted,
            iconType: "/media/ikure/insights/completed-tasks.svg",
        },
        {
            title: "CANCELLED TASK",
            totalCount: taskInsight?.totalTaskCancelled,
            iconType: "/media/ikure/insights/cancelled-tasks.svg",
        },
    ];
    const clearData = () => {
        setFilterData({ fromDate: "", toDate: "" });
    };
    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterData, selectedFacility]);

    return (
        <div className="p-10 card d-flex flex-column">
            <div className="d-flex align-items-center justify-content-between h-auto">
                <div className="">
                    <span className="fs-1 text-dark fw-bolder ">
                        TASK INSIGHTS
                    </span>
                    <i
                        onClick={() => clearData()}
                        className="fas fa-redo fs-3 cursor-pointer ms-5"
                    ></i>
                </div>
                <div className="d-flex align-items-center">
                    {/* <div>
            <button
              className="w-auto 
            btn-flex btn-light btn-primary 
            btn btn-bg-light"
              onClick={handleReport}
            >
              <i className="fas fa-file-contract fs-2x me-2"></i>
              Generate Report
            </button>
          </div> */}
                    <div className="ms-5">
                        <Filter
                            filterData={filterData}
                            setFilterData={setFilterData}
                            // refetch={refetch}
                        />
                        {/* <select
                            className="form-select form-select-lg form-select-solid"
                            onChange={handlePeriod}
                        >
                            <option value="30 Days">30 DAYS</option>
                            <option value="6 Months">6 MONTH</option>
                            <option value="1 Year">1 YEAR</option>
                        </select> */}
                    </div>
                </div>
            </div>
            <div className="row gy-5 gx-xl-8 mt-5">
                {isTaskError ? (
                    <div className="d-flex justify-content-center align-items-center h-400px">
                        <h1 className="text-danger">{errorMsg}</h1>
                    </div>
                ) : (
                    cardList.map((i) => {
                        return isLoading || isFetching ? (
                            <div className="col-xl-4">
                                <InsightsSkeleton />
                            </div>
                        ) : (
                            <div className="col-xl-4">
                                <InfoCard
                                    className="card-xl-stretch mb-xl-3 mb-5"
                                    cardSize={{ height: "200px" }}
                                    iconType={toAbsoluteUrl(i.iconType)}
                                    title={i.title}
                                    totalCount={i.totalCount}
                                />
                            </div>
                        );
                    })
                )}
            </div>
        </div>
    );
};

export default TaskInsights;
