import * as Yup from "yup";
import { FacilityOnboardingTypes } from "../../../../types/FacilityOnboarding.types";
import {
    alphaNumericValidator,
    numberValidator,
    phoneValidator,
    stringValidator,
} from "../../../../common/validators";

export const initsForFacilityOnboarding: FacilityOnboardingTypes = {
    facilityFirstName: "",
    facilityLastName: "",
    email: "",
    telecom: "",
    addressSiteName: "",
    floorNumber: "",
    streetName: "",
    streetNumber: "",
    district: "",
    districtCode: "",
    postalCode: "",
    town: "",
    state: "",
    stateCode: "",
    country: "",
};

export const createAccountSchemas = [
    // Step 1
    Yup.object({
        facilityFirstName: Yup.string()
            .matches(
                stringValidator,
                "Please enter a valid first name (letters and spaces only)"
            )
            .label("First Name"),
        facilityLastName: Yup.string()
            .matches(
                stringValidator,
                "Please enter a valid last name (letters and spaces only)"
            )
            .label("Last Name"),
        telecom: Yup.string()
            .matches(
                phoneValidator,
                "Phone Number must start with 6, 7, 8, or 9, contain only numbers, and be exactly 10 digits"
            )
            .required("Phone Number is Required!"),
        email: Yup.string()
            .email("Please enter a vaild email")
            .required()
            .label("Email Id"),
    }),
    // Step 2
    Yup.object({
        addressSiteName: Yup.string()
            .matches(
                alphaNumericValidator,
                "Address site name should contain only letters, numbers, and spaces"
            )
            .required()
            .label("Address Site"),
        floorNumber: Yup.string()
            .matches(numberValidator, "Floor Number is not valid")
            .label("Floor Number"),
        town: Yup.string()
            .matches(
                alphaNumericValidator,
                "Town should contain only letters, numbers, and spaces"
            )
            .label("Town"),
        streetName: Yup.string()
            .required("Street Name is required")
            .matches(
                alphaNumericValidator,
                "Street Name should contain only letters, numbers, and spaces"
            )
            .label("Street Name"),
        streetNumber: Yup.string().matches(
            alphaNumericValidator,
            "Street Number should contain only letters, numbers, and spaces"
        ),
    }),
    // Step 3
    Yup.object({
        district: Yup.string().required().label("District"),
        postalCode: Yup.string().required().label("Postal Code"),
        state: Yup.string().required().label("State"),
        country: Yup.string().required().label("Country"),
    }),
];
