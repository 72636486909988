import React from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../../../../components/Table";

import Pagination from "../../../../components/Pagination";
import {
    convertUtcTimestampToLocalDate,
    dateFormatter,
} from "../../../../common/utils";

const tableNames = [
    "Indent Id",
    "Created Date",
    "Created By",
    "Status",
    "Action",
];

const IndentTable = ({
    indentList,
    isPageRefreshing,
    isError,
    errorMessage,
    setPage,
    setLimit,
    page,
    limit,
    totalEntites,
}: any) => {
    const navigate = useNavigate();

    return (
        <>
            <Table>
                <TableHeadRow>
                    <TableHead width={140} align="center">
                        SL NO
                    </TableHead>
                    {tableNames?.map((name) => {
                        return (
                            <TableHead width={180} key={name} align="left">
                                {name.toUpperCase()}
                            </TableHead>
                        );
                    })}
                </TableHeadRow>
                <TableBodyContainer
                    isLoading={isPageRefreshing}
                    isError={isError}
                    errorMessage={errorMessage}
                >
                    {indentList?.map((ele: any, idx: number) => {
                        return (
                            <TableRow collapsible={false}>
                                <TableCell align="center">
                                    {page && (page - 1) * limit + idx + 1}
                                </TableCell>
                                <TableCell>
                                    {ele?.indentRequestId
                                        ? ele?.indentRequestId
                                        : "N/A"}
                                </TableCell>
                                <TableCell>
                                    {/* {ele?.createdAt
                                        ? dateFormatter(ele?.createdAt)
                                        : "N/A"} */}
                                    {ele?.createdAt
                                        ? convertUtcTimestampToLocalDate(
                                              ele?.createdAt
                                          )
                                        : "N/A"}
                                </TableCell>
                                <TableCell>
                                    {ele?.createdByUser
                                        ? ele?.createdByUser
                                        : "N/A"}
                                </TableCell>
                                <TableCell>
                                    <span
                                        style={{
                                            color:
                                                ele?.indentStatus === "Accepted"
                                                    ? "#50cd89"
                                                    : ele?.indentStatus ===
                                                      "Draft"
                                                    ? "#FFA533"
                                                    : ele?.indentStatus ===
                                                      "Rejected"
                                                    ? "#FF4933"
                                                    : ele?.indentStatus ===
                                                      "Submitted"
                                                    ? "#FFDA33"
                                                    : "",
                                        }}
                                    >
                                        {ele?.indentStatus
                                            ? ele?.indentStatus
                                            : "N/A"}
                                    </span>
                                </TableCell>
                                <TableCell>
                                    <span
                                        className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                                        onClick={() => {
                                            // TODO: toggle here
                                            ele.indentStatus === "Draft"
                                                ? navigate(
                                                      `/indent/modify-indent?id=${ele?._id}&status=${ele?.indentStatus}`
                                                  )
                                                : navigate(
                                                      `/indent/indent-details?id=${ele?._id}&status=${ele?.indentStatus}`
                                                  );
                                        }}
                                    >
                                        {ele?.indentStatus === "Draft"
                                            ? "EDIT"
                                            : "DETAILS"}
                                        {ele?.indentStatus === "Draft" && (
                                            <i
                                                className="fas fa-edit"
                                                style={{
                                                    marginLeft: "4px",
                                                    paddingBottom: "2px",
                                                }}
                                            ></i>
                                        )}
                                    </span>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBodyContainer>
            </Table>
            <Pagination
                dataCount={totalEntites}
                handleCallback={(val: number | string) => {
                    setLimit(val);
                }}
                handleNext={() =>
                    setPage((prevPageNumber: number) => prevPageNumber + 1)
                }
                handlePrev={() =>
                    setPage((prevPageNumber: number) => prevPageNumber - 1)
                }
                nextDisabled={page === Math.ceil(totalEntites / limit)}
                pageNumber={page}
                pageLimit={limit}
                prevDisabled={page === 1}
            />
        </>
    );
};

export default IndentTable;
