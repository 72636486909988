import * as Yup from "yup";
import { Menu } from "@mui/material";
import { Link } from "react-router-dom";
import { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { ErrorMessage, Field, Formik, Form } from "formik";

import Modal from "../../../../../components/Modal";
import BeneficiaryExport from "../exports/BeneficiaryExport";
import useSearchParams from "../../../../../hooks/useSearchParams";
import {
    convertUtcTimestampToLocalDate,
    dateFormatter,
    todayDate,
} from "../../../../../common/utils/datesUtils";
import CustomTooltip from "../../../../../components/CustomTooltip";
import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../../../../../components/Table";
import Pagination from "../../../../../components/Pagination";
import FilterTile from "../../../../../components/filter-tile";

const filterSchema = Yup.object().shape({
    chwId: Yup.string().label("CHW"),
    visitNumber: Yup.string().label("Visit Number"),
    fromDate: Yup.date().label("From Date"),
    toDate: Yup.date().label("To Date"),
});

const BeneficiaryTable = ({
    filterData,
    beneficiaryListData,
    isLoading,
    isFetching,
    isError,
    FgErrorMsg,
    currentPageNumber,
    handlePageNumber,
    totalEntites,
    totalPages,
    pageLimit,
    setFilterData,
    totalVisits,
    focusedGroupName,
    chwData,
    refetch,
    setPageLimit,
}: any) => {
    const { fgId: id }: any = useSearchParams();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    // Ends Here
    const handleModal = () => {
        setIsOpen(!isOpen);
    };

    const onFilterSubmit = (values: any, onSubmitProps: any) => {
        try {
            const payload = {
                visitNumber: values?.visitNumber,
                chwId: values?.chwId,
                fromDate: values?.fromDate,
                toDate: values?.toDate,
            };
            setFilterData(payload);
            handleClose();
            //   onSubmitProps.resetForm();
        } catch (error) {
            console.log(error);
            handleClose();
            //   onSubmitProps.resetForm();
        }
    };

    const clearData = () => {
        const payload = {
            chwId: "",
            visitNumber: "",
            fromDate: "",
            toDate: "",
        };

        setFilterData(payload);
    };

    const visitArray = Array.from({ length: totalVisits }, (_, i) => i + 1);

    return (
        <div className="card text-dark">
            <Modal
                open={isOpen}
                styles={{
                    width: "650px",
                    backgroundColor: "white",
                }}
            >
                <BeneficiaryExport
                    onClose={handleModal}
                    focusedGroupName={focusedGroupName}
                    visitArray={visitArray}
                />
            </Modal>
            <div className="card-header border-0 d-flex justify-content-between align-items-center pt-5">
                <div className="d-flex align-items-center justify-content-center h-50px">
                    <h1 className="text-uppercase">BENEFICIARY LIST</h1>
                    <i
                        onClick={() => {
                            clearData();
                            refetch();
                        }}
                        className="fas fa-redo fs-3 cursor-pointer ms-5"
                    ></i>
                </div>
                {isError === true ||
                    Array?.isArray(beneficiaryListData) !== true ||
                    (beneficiaryListData?.length > 0 && (
                        <div className="card-toolbar">
                            <button
                                onClick={handleModal}
                                className="btn btn-sm btn-light-primary me-5"
                            >
                                <i className="fas fa-download"></i>
                                EXPORT
                            </button>
                            <button
                                onClick={handleClick}
                                className="btn btn-sm btn-primary"
                            >
                                <i className="fas fa-filter"></i>
                                FILTER
                            </button>

                            {/* Filter Options */}
                            <div>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                    }}
                                >
                                    <Formik
                                        initialValues={filterData}
                                        validationSchema={filterSchema}
                                        onSubmit={onFilterSubmit}
                                        enableReinitialize
                                    >
                                        {(formProps: any) => (
                                            <Form>
                                                <div className="py-5 px-8">
                                                    <div className="w-250px mt-5 mb-5">
                                                        <label className="form-label fs-4 fw-bolder">
                                                            VISIT NUMBER
                                                        </label>
                                                        <Field
                                                            as="select"
                                                            name="visitNumber"
                                                            className="form-select form-select-lg form-select-solid"
                                                        >
                                                            <option value="">
                                                                All
                                                            </option>
                                                            {visitArray?.map(
                                                                (visit) => {
                                                                    return (
                                                                        <>
                                                                            <option
                                                                                value={
                                                                                    visit
                                                                                }
                                                                            >
                                                                                {
                                                                                    visit
                                                                                }
                                                                            </option>
                                                                        </>
                                                                    );
                                                                }
                                                            )}
                                                        </Field>
                                                        <div className="text-danger mt-5">
                                                            <ErrorMessage name="visitNumber" />
                                                        </div>
                                                    </div>
                                                    <div className="w-250px mt-5 mb-5">
                                                        <label className="form-label fs-4 fw-bolder">
                                                            CHW NAME
                                                        </label>
                                                        <Field
                                                            as="select"
                                                            name="chwId"
                                                            className="form-select form-select-lg form-select-solid"
                                                        >
                                                            <option value="">
                                                                All
                                                            </option>
                                                            {chwData?.map(
                                                                (chw: any) => {
                                                                    return (
                                                                        <>
                                                                            <option
                                                                                value={
                                                                                    chw?.chwId
                                                                                }
                                                                            >
                                                                                {
                                                                                    chw?.name
                                                                                }
                                                                            </option>
                                                                        </>
                                                                    );
                                                                }
                                                            )}
                                                        </Field>
                                                        <div className="text-danger mt-5">
                                                            <ErrorMessage name="chwName" />
                                                        </div>
                                                    </div>
                                                    <div className="w-250px mt-5 mb-5">
                                                        <label className="form-label fs-4 fw-bolder">
                                                            FROM DATE
                                                        </label>
                                                        <Field
                                                            id="fromDate"
                                                            as="input"
                                                            type="date"
                                                            name="fromDate"
                                                            max={todayDate}
                                                            className="form-control cursor-pointer"
                                                        />
                                                        <div className="text-danger mt-5">
                                                            <ErrorMessage name="fromDate" />
                                                        </div>
                                                    </div>
                                                    <div className="w-250px mt-5">
                                                        <label className="form-label fs-4 fw-bolder">
                                                            TO DATE
                                                        </label>
                                                        <Field
                                                            id="toDate"
                                                            type="date"
                                                            name="toDate"
                                                            max={todayDate}
                                                            className="form-control"
                                                        />
                                                        <div className="text-danger mt-5">
                                                            <ErrorMessage name="toDate" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="my-5 d-flex justify-content-center align-items-center">
                                                    <button
                                                        type="submit"
                                                        disabled={
                                                            !formProps.isValid ||
                                                            formProps.isSubmitting
                                                        }
                                                        className="w-250px btn btn-sm btn-primary"
                                                    >
                                                        SUBMIT
                                                    </button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </Menu>
                            </div>
                            {/* End Here */}
                        </div>
                    ))}
            </div>
            <FilterTile
                filterData={filterData}
                keyNames={{
                    chwId: "CHW Id",
                    visitNumber: "Visit Number",
                    fromDate: "Start date",
                    toDate: "End date",
                }}
                clearAll={() =>
                    setFilterData({
                        chwId: "",
                        visitNumber: "",
                        fromDate: "",
                        toDate: "",
                    })
                }
            />
            <div className="card-body py-3">
                <Table>
                    <TableHeadRow>
                        <TableHead width={120} align={"center"}>
                            SL NO
                        </TableHead>
                        <TableHead width={140}>BENEFICIARY</TableHead>
                        <TableHead width={140} align="center">
                            AGE & GENDER
                        </TableHead>
                        <TableHead width={120} align="center">
                            VISIT NUMBER
                        </TableHead>
                        <TableHead width={120}>VISITED BY</TableHead>
                        <TableHead width={120}>VISIT DATE</TableHead>
                        <TableHead width={170}>ACTION</TableHead>
                    </TableHeadRow>

                    {isLoading || isFetching === true ? (
                        <tbody
                            style={{
                                height: "400px",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "relative",
                                left: "500px",
                                top: "50px",
                            }}
                        >
                            <ClipLoader size={70} speedMultiplier={0.6} />
                        </tbody>
                    ) : isError === true ? (
                        <tbody
                            style={{
                                height: "400px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "absolute",
                                left: "35%",
                                top: "200px",
                            }}
                        >
                            <h1 className="text-danger">
                                {isError
                                    ? FgErrorMsg
                                    : "Something went wrong!!"}
                            </h1>
                        </tbody>
                    ) : Array?.isArray(beneficiaryListData) ? (
                        <TableBodyContainer isLoading={false}>
                            {beneficiaryListData?.map(
                                (item: any, index: any) => {
                                    const dateFormat = new Date(
                                        item?.createdAt
                                    );
                                    return (
                                        <TableRow
                                            collapsible={false}
                                            key={index}
                                        >
                                            <TableCell>
                                                {currentPageNumber &&
                                                    (currentPageNumber - 1) *
                                                        pageLimit +
                                                        index +
                                                        1}
                                            </TableCell>
                                            <TableCell>
                                                <Link
                                                    className="text-dark text-dark fw-bolder text-hover-primary"
                                                    to={`/patient/overview?pId=${item?.patientData[0]?.uhid}`}
                                                >
                                                    {item?.patientData[0]?.name
                                                        ? item?.patientData[0]
                                                              ?.name
                                                        : "N/A"}
                                                </Link>
                                            </TableCell>

                                            <TableCell align="center">
                                                <CustomTooltip
                                                    title={
                                                        item?.patientData[0]
                                                            ?.demographicDetails
                                                            ?.sex
                                                            ? item
                                                                  ?.patientData[0]
                                                                  ?.demographicDetails
                                                                  ?.sex
                                                            : "N/A"
                                                    }
                                                    placement="top"
                                                >
                                                    <span className="border-none">
                                                        {item?.patientData[0]
                                                            ?.demographicDetails
                                                            ?.age
                                                            ? item
                                                                  ?.patientData[0]
                                                                  ?.demographicDetails
                                                                  ?.age
                                                            : "N/A"}
                                                    </span>
                                                </CustomTooltip>
                                            </TableCell>

                                            <TableCell align="center">
                                                {item?.visitNumber}
                                            </TableCell>

                                            <TableCell>
                                                {item?.chwData[0]?.name
                                                    ? item?.chwData[0]?.name
                                                    : "N/A"}
                                            </TableCell>

                                            <TableCell>
                                                {/* {item?.createdAt
                                                    ? dateFormatter(dateFormat)
                                                    : "N/A"} */}
                                                {item?.createdAt
                                                    ? convertUtcTimestampToLocalDate(
                                                          item?.createdAt
                                                      )
                                                    : "N/A"}
                                            </TableCell>

                                            <TableCell>
                                                <Link
                                                    state={{
                                                        id: item._id,
                                                    }}
                                                    to={{
                                                        pathname: `/focused-group/entry-details`,
                                                        search: `?fgId=${id}`,
                                                    }}
                                                    className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                                                >
                                                    ENTRIES
                                                </Link>
                                                <Link
                                                    state={{
                                                        taskId: item?.taskId,
                                                        uhid: item?.uhid,
                                                    }}
                                                    to={{
                                                        pathname: `/focused-group/vital-details`,
                                                        search: `?fgId=${id}`,
                                                    }}
                                                    className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                                                >
                                                    VITALS
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            )}
                        </TableBodyContainer>
                    ) : (
                        <tbody
                            style={{
                                height: "400px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "absolute",
                                left: "400px",
                                top: "200px",
                            }}
                        >
                            <h1 className="text-danger">
                                {beneficiaryListData}
                            </h1>
                        </tbody>
                    )}
                </Table>
                {/* </div> */}
                {/* Pagination */}
                {!isError && (
                    <Pagination
                        dataCount={totalEntites}
                        handleCallback={(val: number | string) => {
                            setPageLimit(+val);
                        }}
                        handleNext={() =>
                            handlePageNumber(
                                (prevPageNumber: number) => prevPageNumber + 1
                            )
                        }
                        handlePrev={() =>
                            handlePageNumber(
                                (prevPageNumber: number) => prevPageNumber - 1
                            )
                        }
                        nextDisabled={currentPageNumber === totalPages}
                        pageNumber={currentPageNumber}
                        pageLimit={pageLimit}
                        prevDisabled={currentPageNumber === 1}
                    />
                )}

                {/* end::Table */}
            </div>
            {/* end::Table container */}
        </div>
    );
};

export default BeneficiaryTable;
