import { useEffect, useState } from "react";
import { convertToUtcTimestamp, todayDate } from "../../common/utils";
import { useNavbar } from "../../context/NavbarContext";
import useMutateRequest from "../../hooks/getMutation.query";
import useGetRequest from "../../hooks/getRequest.query";
import { getAllDoctors } from "../../services/doctor/DoctorManagement.hook";
import { getAllCHWByFacility } from "../../services/focused-group/FocusedGroup.hook";
import { getPatientConsultationsByFacility } from "../../services/patient/PatientAppointments.hook";

import AppointmentCounterPage from "./AppointmentCounterPage";
import AppointmentListTable from "./table/AppointmentListTable";

const AppointmentPage = () => {
    const { selectedFacility } = useNavbar();
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const [filterData, setFilterData]: any = useState({
        // fromDate: todayDate,
        // toDate: todayDate,
        fromDate: "",
        toDate: "",
        doctorName: "",
        chwName: "",
        consultationType: "",
        specialization: "",
        status: "",
    });

    const facilityCode: any = localStorage.getItem("facilityCode");

    const {
        data,
        isLoading,
        error,
        mutate,
        isError: isPatientConsultationError,
    }: any = useMutateRequest((data: any) =>
        getPatientConsultationsByFacility({
            facilityCode,
            page: currentPageNumber,
            limit: pageLimit,
            fromDate:
                filterData?.fromDate !== ""
                    ? convertToUtcTimestamp(filterData?.fromDate)
                    : "",
            toDate:
                filterData?.toDate !== ""
                    ? convertToUtcTimestamp(filterData?.toDate)
                    : "",
            status: filterData?.status,
            consultationType: filterData?.consultationType,
            specialization: filterData?.specialization,
            doctorName: filterData?.doctorName,
            chwName: filterData?.chwName,
        })
    );

    const patientConsultationDetails: any = data?.data?.data?.consultation;
    const totalPatientAppointmentCount: any =
        data?.data?.data?.consultationCount;
    const totalPatientConsultationEntites = data?.data?.results;

    const totalPatientConsultationPages = Math.ceil(
        totalPatientConsultationEntites / pageLimit
    );

    const errorMsg = error?.response?.data?.message?.message;
    const successMsg = data?.data?.data?.message;

    const getData = () => {
        try {
            let payload = {
                facilityCode,
                // fromDate: filterData?.fromDate,
                // toDate: filterData?.toDate,
                fromDate:
                    filterData?.fromDate !== ""
                        ? convertToUtcTimestamp(filterData?.fromDate)
                        : "",
                toDate:
                    filterData?.toDate !== ""
                        ? convertToUtcTimestamp(filterData?.toDate)
                        : "",
                doctorName: filterData?.doctorName,
                chwName: filterData?.chwName,
            };
            mutate(payload, {
                onSuccess: (data: any) => {},
                onError: (error: any) => {
                    console.log(error);
                },
            });
        } catch (error) {
            console.log(error);
        }
    };

    const { data: allCHW, refetch: refetchCHW }: any = useGetRequest(
        "allCHWData",
        async () =>
            await getAllCHWByFacility({
                facilityCode,
                page: 1,
                limit: 20,
            }),
        {
            retry: false,
            staleTime: 100000,
            refetchOnWindowFocus: false,
        }
    );
    const chwData = allCHW?.data?.data?.chw;

    const { data: doctorData, refetch: refetchDoctor }: any = useGetRequest(
        "getAllDoctors",
        async () => await getAllDoctors(),
        {
            retry: false,
            staleTime: 100000,
            refetchOnWindowFocus: false,
        }
    );
    const doctorDetails = doctorData?.data?.data?.doctor;

    useEffect(() => {
        getData();
        refetchDoctor();
        refetchCHW();
    }, [currentPageNumber, filterData, selectedFacility]);

    return (
        <>
            <div>
                <AppointmentCounterPage
                    filterData={filterData}
                    totalPatientConsultationEntites={
                        totalPatientConsultationEntites
                    }
                    isLoading={isLoading}
                    totalPatientAppointmentCount={totalPatientAppointmentCount}
                    isPatientConsultationError={isPatientConsultationError}
                />
            </div>
            <div className="mt-5">
                <AppointmentListTable
                    setCurrentPageNumber={setCurrentPageNumber}
                    currentPageNumber={currentPageNumber}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    patientConsultationDetails={patientConsultationDetails}
                    doctorDetails={doctorDetails}
                    chwData={chwData}
                    errorMsg={errorMsg}
                    isLoading={isLoading}
                    totalPatientConsultationPages={
                        totalPatientConsultationPages
                    }
                    isPatientConsultationError={isPatientConsultationError}
                    pageLimit={pageLimit}
                    setPageLimit={setPageLimit}
                    totalPatientConsultationEntites={
                        totalPatientConsultationEntites
                    }
                    facilityCode={facilityCode}
                />
            </div>
        </>
    );
};

export default AppointmentPage;
