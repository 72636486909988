import { FieldProps } from "formik";
import Select, { Options, MultiValue, SingleValue } from "react-select";

interface Option {
    label: string;
    value: string;
}

interface CustomSelectProps extends FieldProps {
    options: Options<Option>;
    isMulti?: boolean;
    className?: string;
    placeholder?: string;
    isDisabled?: boolean;
    defaultInputValue?: any;
}

export const CustomSelect = ({
    className,
    placeholder,
    field,
    form,
    options,
    isMulti = false,
    isDisabled = false,
    defaultInputValue,
}: CustomSelectProps) => {
    const onChange = (newValue: MultiValue<Option> | SingleValue<Option>) => {
        form.setFieldValue(
            field.name,
            isMulti
                ? (newValue as MultiValue<Option>).map(
                      (item: Option) => item.value
                  )
                : (newValue as SingleValue<Option>)?.value
        );
    };

    const getValue = () => {
        if (options && field.value) {
            return isMulti
                ? options.filter(
                      (option) =>
                          Array.isArray(field.value) &&
                          field.value.includes(option.value)
                  )
                : options.find((option) => option.value === field.value);
        } else {
            return isMulti ? [] : null;
        }
    };

    return (
        <Select
            data-testid="language-input"
            className={className}
            name={field.name}
            value={getValue()}
            onChange={onChange}
            onBlur={() => form.setFieldTouched(field.name, true)} // Ensure Formik knows the field was touched
            placeholder={placeholder}
            options={options}
            isMulti={isMulti}
            isDisabled={isDisabled}
            defaultInputValue={defaultInputValue}
            aria-label="Select an option"
        />
    );
};

export default CustomSelect;
