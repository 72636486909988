import { useEffect, useState } from "react";

import ClipLoader from "react-spinners/ClipLoader";

import { TaskListTable } from "./table/TaskListTable";
import { CHWHeader } from "./components/CHWHeader";

import CHWInsights from "./components/CHWInsights";

import { AttendanceListTable } from "./table/AttendanceListTable";
import AnalyticsPage from "./analytics/AnalyticsPage";
import TabContainer from "../../components/Tabs/Tabs";
import Tab from "../../components/Tabs/Tab";
import useMutateRequest from "../../hooks/getMutation.query";
import useGetRequest from "../../hooks/getRequest.query";
import useSearchParams from "../../hooks/useSearchParams";
import {
    getCHWProfile,
    getCHWInsights,
} from "../../services/CHWManagement.hook";

// import { checkSubModules, moduleInfo } from "../../rbac/ds";
// import info from "./info.json";
import { hasAccessToModule, hasAccessToSubModule } from "../../rbac";
import { Permissions } from "../../rbac/types";
import { convertToUtcTimestamp } from "../../common/utils";

export const CHWOverview = () => {
    const [insightPeriod, setInsightPeriod] = useState("30 Days");
    const { chwId: id, tab }: any = useSearchParams();

    const [tabInitials, setTabInitials] = useState("overview");

    useEffect(() => {
        if (tab) {
            setTabInitials(tab);
        }
    }, [tab, tabInitials]);

    const facilityCode: any = localStorage.getItem("facilityCode");

    const { data, isLoading, mutate }: any = useMutateRequest((data: any) =>
        getCHWProfile(data)
    );
    const chwDetails = data?.data?.data?.chwDetails[0];
    const chwName = chwDetails?.name;

    const [filterData, setFilterData]: any = useState({
        fromDate: "",
        toDate: "",
    });

    const {
        data: chwInsightsData,
        isLoading: isCHWInsightsLoading,
        isFetching: isCHWInsightsFetching,
        refetch,
    }: any = useGetRequest(
        ["getCHWInsights", id, filterData],
        (data: any) =>
            getCHWInsights({
                facilityCode,
                chwId: id,
                // period: insightPeriod,
                // ...filterData,
                fromDate:
                    filterData?.fromDate !== ""
                        ? convertToUtcTimestamp(filterData?.fromDate)
                        : "",
                toDate:
                    filterData?.toDate !== ""
                        ? convertToUtcTimestamp(filterData?.toDate)
                        : "",
            }),
        {
            refetchOnWindowFocus: false,
            enabled: !!id,
        }
    );

    const chwInsightsPerDay = chwInsightsData?.data?.data?.chwCountPerDay;
    const chwInsights = chwInsightsData?.data?.data?.chwInsights;

    const getData = () => {
        try {
            const formData = new FormData();
            formData.append("chwId", id);
            formData.append("facilityCode", facilityCode);
            if (id) {
                mutate(formData, {
                    onSuccess: (data: any) => {},
                    onError: (error: any) => {
                        console.log(error);
                    },
                });
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        getData();
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterData]);
    // const rbacInfo = moduleInfo();
    // const metaInfo = info;
    // checkSubModules(metaInfo?.submodules);

    // console.log(rbacInfo?.subModules);
    // console.log(checkSubModules(metaInfo?.submodules));
    const canReadDashboard = hasAccessToModule("DASHBOARD", Permissions.read);

    // const canReadDashboard = hasAccessToSubModule("LIST_COURSE","" Permissions.read);
    const canViewForm = hasAccessToModule("DASHBOARD", Permissions.view_form);

    return (
        <>
            {isLoading ? (
                <div className="card w-100 h-500px d-flex flex-column align-items-center justify-content-center p-15">
                    <ClipLoader size={70} speedMultiplier={0.6} />
                </div>
            ) : (
                <>
                    <div className="#">
                        <CHWHeader
                            id={id}
                            chwDetails={chwDetails}
                            chwInsightsPerDay={chwInsightsPerDay}
                            isCHWInsightsLoading={isCHWInsightsLoading}
                            isCHWInsightsFetching={isCHWInsightsFetching}
                        />
                    </div>

                    <TabContainer
                        // hiddenInfo={metaInfo?.submodules?.includes(tabInitials)}
                        initialValue={tabInitials}
                    >
                        {/* {!metaInfo?.submodules?.includes("overview") && ( */}
                        <Tab eventKey="overview" title="OVERVIEW">
                            <CHWInsights
                                filterData={filterData}
                                setFilterData={setFilterData}
                                chwDetails={chwDetails}
                                chwInsights={chwInsights}
                                isCHWInsightsLoading={isCHWInsightsLoading}
                                isCHWInsightsFetching={isCHWInsightsFetching}
                                insightPeriod={insightPeriod}
                                setInsightPeriod={setInsightPeriod}
                            />
                        </Tab>
                        {/* )} */}
                        <Tab
                            // hidden={metaInfo?.submodules?.includes("tasklist")}
                            eventKey="tasklist"
                            title="TASK LIST"
                        >
                            <TaskListTable chwName={chwName} />
                        </Tab>
                        <Tab eventKey="attendance" title="ATTENDANCE LIST">
                            <AttendanceListTable />
                        </Tab>
                        <Tab
                            eventKey="performance"
                            title="PERFORMANCE MONITORING"
                        >
                            <AnalyticsPage chwName={chwName} />
                        </Tab>
                    </TabContainer>
                </>
            )}
        </>
    );
};
