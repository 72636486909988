import { useEffect, useState } from "react";
import Pagination from "../../../components/Pagination";
import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../../../components/Table";
import { useNavbar } from "../../../context/NavbarContext";
import useMutateRequest from "../../../hooks/getMutation.query";
import { getAllReports } from "../../../services/report/ReportGeneration.hook";
import { Permissions, hasAccessToModule } from "../../../rbac";
import useSearchParams from "../../../hooks/useSearchParams";
import {
    convertUtcTimestampToLocalDate,
    dateFormatter,
} from "../../../common/utils";

const ReportTable = ({ allReportList }: any) => {
    const { selectedFacility } = useNavbar();
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const searchParams: any = useSearchParams();
    const [reportType, setReportType] = useState("");

    useEffect(() => {
        if (searchParams) {
            setReportType(searchParams?.report);
            getData();
        }
    }, [searchParams]);

    // API For fetaching all reports
    const { data, mutate, isError, error, isLoading }: any = useMutateRequest(
        (data: string) =>
            getAllReports({
                reportType: Number(reportType),
                page: currentPageNumber,
                limit: pageLimit,
            })
    );

    const totalEntites = data?.data?.results;
    const allReports = data?.data?.data?.report;
    const totalPages = Math.ceil(totalEntites / pageLimit);
    const errorMsg = error?.response?.data?.message;

    const getData = () => {
        try {
            let payload = {
                reportType: Number(reportType),
                page: currentPageNumber,
                limit: pageLimit,
            };

            mutate(payload, {
                onSuccess: (data: any) => {},
                onError: (error: any) => {},
            });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getData();
    }, [currentPageNumber, reportType, selectedFacility, pageLimit]);

    const tableNames = ["REPORT NAME", "GENERATED BY", "DATE & TIME", "STATUS"];

    const canDownload = hasAccessToModule(
        "LIST_REPORT",
        Permissions.export_button
    );
    return (
        <div className="card text-dark">
            <div className="card-header border-0 d-flex justify-content-between align-items-center pt-5">
                <div className="d-flex align-items-center justify-content-center h-50px">
                    <h1>REPORT LIST</h1>
                </div>
                <div className="card-toolbar">
                    <select
                        value={reportType}
                        onChange={(e: any) => setReportType(e.target.value)}
                        className="form-select form-select-solid form-select-lg cursor-pointer"
                    >
                        {allReportList?.map((report: any) => {
                            return (
                                <>
                                    <option value={report?.optionValue}>
                                        {report?.optionName}
                                    </option>
                                </>
                            );
                        })}
                    </select>
                </div>
            </div>

            <div className="card-body py-3">
                <Table>
                    <TableHeadRow>
                        <TableHead width={140} align="center">
                            SL NO
                        </TableHead>

                        {tableNames?.map((name) => {
                            return (
                                <TableHead width={180} key={name} align="left">
                                    {name}
                                </TableHead>
                            );
                        })}
                        <TableHead width={140} align="center">
                            DOWNLOAD
                        </TableHead>
                    </TableHeadRow>

                    <TableBodyContainer
                        isLoading={isLoading}
                        isError={isError}
                        errorMessage={errorMsg}
                    >
                        {allReports?.map((item: any, index: any) => {
                            const reportName = item?.reportType
                                .split(/(?=[A-Z])/)
                                .join(" ");

                            return (
                                <TableRow collapsible={false} key={index}>
                                    <TableCell align="center">
                                        {currentPageNumber &&
                                            (currentPageNumber - 1) *
                                                pageLimit +
                                                index +
                                                1}
                                    </TableCell>
                                    <TableCell>
                                        <span className=" text-capitalize">
                                            {reportName}
                                        </span>
                                    </TableCell>
                                    <TableCell>
                                        {item?.reportGeneratedById
                                            ? item?.reportGeneratedById
                                            : "N/A"}
                                    </TableCell>
                                    <TableCell>
                                        {item?.createdAt
                                            ? convertUtcTimestampToLocalDate(
                                                  item?.createdAt
                                              )
                                            : "N/A"}
                                    </TableCell>

                                    <TableCell>
                                        <span
                                            className={`badge badge-light-${
                                                item?.reportStatus ===
                                                    "No Data" ||
                                                item?.reportStatus === "NoData"
                                                    ? "danger"
                                                    : item?.reportStatus ===
                                                      "Processing"
                                                    ? "info"
                                                    : item?.reportStatus ===
                                                      "Done"
                                                    ? "success"
                                                    : null
                                            } text-center `}
                                        >
                                            {item?.reportStatus}
                                        </span>
                                    </TableCell>

                                    <TableCell align="center">
                                        {item?.reportStatus === "Done" ? (
                                            <>
                                                <a
                                                    hidden={!canDownload}
                                                    href={item?.reportFile}
                                                    target="_blank"
                                                >
                                                    <i
                                                        // onClick={handleAlert}
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        className="fs-2 fas fa-arrow-circle-down text-hover-primary"
                                                    ></i>
                                                </a>
                                            </>
                                        ) : (
                                            <i className="fs-2 fas fa-times-circle text-hover-danger"></i>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBodyContainer>
                </Table>

                {/* Pagination */}
                <Pagination
                    dataCount={totalEntites}
                    handleCallback={(val) => setPageLimit(+val)}
                    handleNext={() =>
                        setCurrentPageNumber(
                            (prevPageNumber: number) => prevPageNumber + 1
                        )
                    }
                    handlePrev={() =>
                        setCurrentPageNumber(
                            (prevPageNumber: number) => prevPageNumber - 1
                        )
                    }
                    nextDisabled={currentPageNumber === totalPages}
                    prevDisabled={currentPageNumber === 1}
                    pageLimit={pageLimit}
                    pageNumber={currentPageNumber}
                />
            </div>
        </div>
    );
};

export default ReportTable;
