import { Field, Form, Formik, ErrorMessage } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
// import {
//     patientExportValidationSchema,
//     patientInitalValues,
// } from "../../model/PatientExportModel";
import { generateReport } from "../../../../../../services/report/ReportGeneration.hook";
import useGetRequest from "../../../../../../hooks/getRequest.query";
import useMutateRequest from "../../../../../../hooks/getMutation.query";
import { useNavbar } from "../../../../../../context/NavbarContext";
import { getAllCHWByFacility } from "../../../../../../services/focused-group/FocusedGroup.hook";
import {
    convertToUtcTimestamp,
    todayDate,
} from "../../../../../../common/utils";

import { getAllCHWForSales } from "../../../../../../services/supply-chain/sales-order/sales-order-list/sales-order-list";
import _ from "lodash";
import {
    salesOrderExportValidationSchema,
    salesOrderInitalValues,
} from "./SalesOrderExportModel";

type ExportProps = {
    onClose: () => void;
    styles?: any;
};

const SalesOrderExport = ({ onClose, styles }: ExportProps) => {
    const facilityName: any = localStorage.getItem("facilityName");
    const facilityCode: any = localStorage.getItem("facilityCode");
    const { selectedFacility, userRole } = useNavbar();
    const navigate = useNavigate();
    const [msg, setMsg] = useState<boolean>(false);
    const [spinner, setSpinner] = useState<boolean>(false);

    // API Call
    const { mutate, isLoading, isError, error, isSuccess } = useMutateRequest(
        (data: any) => generateReport(data)
    );
    const { data } = useGetRequest(["getChwList"], () =>
        getAllCHWForSales({ facilityCode, page: 1, limit: 1000 })
    );
    const chwList = useMemo(() => {
        return data?.data?.data?.chw?.map((ele: any) => {
            return { label: _.startCase(ele.name), chwId: ele.chwId };
        });
    }, [data]);

    const {
        data: allCHW,
        refetch,
        isFetching,
    }: any = useGetRequest(
        "allCHWData",
        async () =>
            await getAllCHWByFacility({
                facilityCode,
                page: 1,
                limit: 20,
            }),
        {
            retry: false,
            staleTime: 100000,
            refetchOnWindowFocus: false,
        }
    );
    const chwData = allCHW?.data?.data?.chw;

    useEffect(() => {
        refetch();
    }, [selectedFacility]);

    useEffect(() => {
        if (isSuccess === true) {
            setSpinner(true);
            setTimeout(() => {
                onClose();
                setSpinner(false);
                navigate("/report/report-management");
            }, 3500);
        }
    }, [isSuccess]);

    const onSubmit = (values: any) => {
        let fullPayload = [
            {
                filterName: "startDate",
                filterValue: convertToUtcTimestamp(values?.fromDate),
            },
            {
                filterName: "endDate",
                filterValue: convertToUtcTimestamp(values?.toDate),
            },
            {
                filterName: "chwId",
                filterValue: values?.chwId,
            },
            {
                filterName: "facilityCode",
                filterValue: values?.facilityCode
                    ? values?.facilityCode
                    : facilityCode,
            },
            {
                filterName: "paymentStatus",
                filterValue: values?.paymentStatus,
            },
            {
                filterName: "deliveryStatus",
                filterValue: values?.deliveryStatus,
            },
            {
                filterName: "salesOrderType",
                filterValue: values?.salesOrderType,
            },
        ]?.filter((item) => item?.filterValue !== "");

        let payload: any = {
            reportType: "exportSalesOrder",
            reportRepository: "inventory",
            filter: fullPayload,
        };

        try {
            mutate(payload, {
                onSuccess: (data: any) => {
                    setMsg(true);
                    setSpinner(true);
                    setTimeout(() => {
                        setSpinner(false);
                    }, 2000);
                },
                onError: (error: any) => {
                    setMsg(true);
                    setSpinner(true);
                    setTimeout(() => {
                        setSpinner(false);
                    }, 2000);
                },
            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            {isLoading || isFetching || spinner ? (
                <div
                    style={{ backgroundColor: "transparent" }}
                    className="h-225px d-flex flex-column justify-content-center align-items-center"
                >
                    <ClipLoader size={70} speedMultiplier={0.6} />
                </div>
            ) : msg ? (
                <div
                    style={{
                        backgroundColor: styles?.backgroundColor,
                        width: styles?.width,
                        height: styles?.msgHeight,
                        padding: styles?.padding,
                    }}
                    className="d-flex flex-column justify-content-center align-items-center"
                >
                    {isError === true ? (
                        <>
                            <p className="fs-1 fw-bolder text-center text-dark">
                                Failed to submit report
                            </p>
                            <div className="mt-5 d-flex justify-content-center align-items-center">
                                <button
                                    onClick={onClose}
                                    className="w-150px btn btn-lg btn-danger me-5"
                                >
                                    Close
                                </button>
                                <button
                                    onClick={() => setMsg(false)}
                                    className="w-150px btn btn-lg btn-primary me-5"
                                >
                                    Please Retry
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <p className="fs-1 fw-bolder text-center text-dark">
                                Your request has been successfully submitted
                            </p>
                            <div className="mt-5 d-flex justify-content-center align-items-center">
                                <button
                                    onClick={onClose}
                                    className="w-150px btn btn-lg btn-danger me-5"
                                >
                                    Close
                                </button>
                            </div>
                        </>
                    )}
                </div>
            ) : (
                <div
                    style={{
                        backgroundColor: styles?.backgroundColor,
                        width: styles?.width,
                        height: styles?.height,
                        padding: styles?.padding,
                    }}
                >
                    <Formik
                        initialValues={salesOrderInitalValues}
                        validationSchema={salesOrderExportValidationSchema}
                        onSubmit={onSubmit}
                    >
                        {(formProps: any) => (
                            <Form>
                                <div
                                    style={{
                                        height: "450px",
                                        overflow: "hidden",
                                        overflowY: "scroll",
                                        scrollbarWidth:
                                            "-moz-initial" /* Firefox */,
                                    }}
                                >
                                    <h1 className="text-center text-hover-primary">
                                        EXPORT SALE ORDER
                                    </h1>
                                    <div className="mt-5">
                                        <label
                                            htmlFor="exportFormat"
                                            className="form-label fs-4 fw-bolder"
                                        >
                                            EXPORT FORMAT
                                        </label>
                                        <Field
                                            id="exportFormat"
                                            as="select"
                                            name="exportFormat"
                                            disabled
                                            className="form-select form-select-light form-select-lg required"
                                            style={{ cursor: "not-allowed" }}
                                        >
                                            <option value="EXCEL">EXCEL</option>
                                            <option value="CSV">CSV</option>
                                            <option value="PDF">PDF</option>
                                        </Field>
                                        <div className="text-danger mt-5">
                                            <ErrorMessage name="exportFormat" />
                                        </div>
                                        <div className="text-muted fw-bolder mt-2 ms-2">
                                            By Default the Data would be
                                            exported in EXCEL Format.
                                        </div>
                                    </div>
                                    <div className="my-5">
                                        <label className="form-label fs-4 fw-bolder">
                                            DELIVERY STATUS
                                        </label>
                                        <Field
                                            as="select"
                                            name="deliveryStatus"
                                            className="form-select form-select-lg form-select-solid"
                                            data-testid="deliveryStatus"
                                        >
                                            <option value="">All</option>
                                            <option value="Order Placed">
                                                Order Placed
                                            </option>
                                            <option value="Order Packed">
                                                Order Packed
                                            </option>
                                            <option value="Order Shipped">
                                                Order Shipped
                                            </option>
                                            <option value="Order Delivered">
                                                Order Delivered
                                            </option>
                                            <option value="Order Cancelled">
                                                Order Cancelled
                                            </option>
                                        </Field>
                                        <div className="text-danger mt-5">
                                            <ErrorMessage name="deliveryStatus" />
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <label
                                            htmlFor="facilityCode"
                                            className="form-label fs-4 fw-bolder"
                                        >
                                            FACILITY NAME
                                        </label>
                                        <Field
                                            id="facilityCode"
                                            as="select"
                                            name="facilityCode"
                                            className="form-select form-select-light form-select-lg"
                                            disabled={
                                                userRole === "FACILITY MANAGER"
                                            }
                                            style={{
                                                cursor:
                                                    userRole ===
                                                    "FACILITY MANAGER"
                                                        ? "not-allowed"
                                                        : "",
                                            }}
                                        >
                                            {userRole === "FACILITY MANAGER" ? (
                                                <option value={facilityCode}>
                                                    {facilityName}
                                                </option>
                                            ) : (
                                                <>
                                                    <option value="">
                                                        All
                                                    </option>
                                                    <option
                                                        value={facilityCode}
                                                    >
                                                        {facilityName}
                                                    </option>
                                                </>
                                            )}
                                        </Field>
                                        <div className="text-danger mt-5">
                                            <ErrorMessage name="facilityCode" />
                                        </div>
                                    </div>
                                    {/* <div className="my-5">
                                    <label className="form-label fs-4 fw-bolder">
                                        CHW NAME
                                    </label>
                                    <Autocomplete
                                        data-testid="chwName"
                                        disablePortal
                                        autoHighlight
                                        id="combo-box-demo"
                                        options={chwList}
                                        style={{ width: "250px" }}
                                        sx={{ width: 300 }}
                                        // className="form-control form-control-solid"
                                        onChange={(e, value: any) => {
                                            // setSelectedProductDetails(value);
                                            formProps?.setFieldValue(
                                                "chwName",
                                                value?.chwId
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                    />

                                    <div className="text-danger mt-2">
                                        <ErrorMessage name="chwName" />
                                    </div>
                                </div> */}
                                    <div className="my-5">
                                        <label className="form-label fs-4 fw-bolder">
                                            ORDER FROM
                                        </label>
                                        <Field
                                            as="select"
                                            name="salesOrderType"
                                            className="form-select form-select-lg form-select-solid"
                                            data-testid="salesOrderType"
                                        >
                                            <option value="">All</option>
                                            <option value="Clinic">
                                                Clinic
                                            </option>
                                            <option value="Camp">Camp</option>
                                            <option value="Doorstep">
                                                Doorstep
                                            </option>
                                        </Field>
                                        <div className="text-danger mt-5">
                                            <ErrorMessage name="salesOrderType" />
                                        </div>
                                    </div>
                                    <div className="my-5">
                                        <label className="form-label fs-4 fw-bolder">
                                            PAYMENT STATUS
                                        </label>
                                        <Field
                                            as="select"
                                            name="paymentStatus"
                                            className="form-select form-select-lg form-select-solid"
                                            data-testid="paymentStatus"
                                        >
                                            <option value="">All</option>
                                            <option value="Pending">
                                                Pending
                                            </option>
                                            <option value="Partially Paid">
                                                Partially Paid
                                            </option>
                                            <option value="Paid">Paid</option>
                                            <option value="Not Applicable">
                                                Not Applicable
                                            </option>
                                        </Field>
                                        <div className="text-danger mt-5">
                                            <ErrorMessage name="paymentStatus" />
                                        </div>
                                    </div>
                                    <div className="my-5">
                                        <label className="form-label fs-4 fw-bolder">
                                            CHW NAME
                                        </label>
                                        <Field
                                            as="select"
                                            name="chwId"
                                            className="form-select form-select-lg form-select-solid"
                                        >
                                            <option value="">All</option>
                                            {chwData?.map((chw: any) => {
                                                return (
                                                    <>
                                                        <option
                                                            value={chw?.chwId}
                                                        >
                                                            {chw?.name}
                                                        </option>
                                                    </>
                                                );
                                            })}
                                        </Field>
                                        <div className="text-danger mt-5">
                                            <ErrorMessage name="chwId" />
                                        </div>
                                    </div>
                                    <div className="my-5">
                                        <label className="form-label required fs-4 fw-bolder">
                                            FROM DATE
                                        </label>
                                        <Field
                                            id="fromDate"
                                            as="input"
                                            type="date"
                                            name="fromDate"
                                            max={todayDate}
                                            className="form-control cursor-pointer"
                                        />
                                        <div className="text-danger mt-5">
                                            <ErrorMessage name="fromDate" />
                                        </div>
                                    </div>
                                    <div className="my-5">
                                        <label className="form-label required fs-4 fw-bolder">
                                            TO DATE
                                        </label>
                                        <Field
                                            id="toDate"
                                            type="date"
                                            name="toDate"
                                            max={todayDate}
                                            className="form-control"
                                        />
                                        <div className="text-danger mt-5">
                                            <ErrorMessage name="toDate" />
                                        </div>
                                    </div>

                                    <div className="mt-10 d-flex justify-content-center align-items-center">
                                        <button
                                            onClick={onClose}
                                            className="btn btn-md btn-danger me-5"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            disabled={
                                                !formProps.isValid ||
                                                formProps.isSubmitting
                                            }
                                            className="btn btn-md btn-primary"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            )}
        </>
    );
};

export default SalesOrderExport;
